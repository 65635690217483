export const env = {

    BASE_PATH : process.env.REACT_APP_BASE_PATH,
    PUBLIC_URL: process.env.PUBLIC_URL,
    SECRETO: process.env.REACT_APP_SECRETO,
    API_END_POINT: process.env.REACT_APP_API_URL_END_POINT,
    FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
    FIREBASE_AUTH_DOMAIN:process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID:process.env.REACT_APP_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET:process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID:process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID:process.env.REACT_APP_FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

